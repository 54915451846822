@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-black: #18181b;
  --color-dark-black: #121212;
  --color-white: #ffffff;
  --color-pink: #f6abb7;
  --color-home-pink: #f6ebe7;
  --color-gray: #777777;
  --color-hot-pink: #e95d7a;
  --color-blue: #87e6de;
  --color-dark-white: #f6f6f6;

  --font-primary: 'Andika', sans-serif;
}

* {
  box-sizing: border-box;
}
html {
  font-family: var(--font-primary);
}
.apply-bg {
  background-color: #18181b;
}
.apply-gradient {
  @apply bg-dark-gradient;
}

.site-button {
  @apply relative rounded-md py-2 px-6 text-sm font-medium text-white border border-white hover:border-[#fda7bf] bg-transparent transition-all duration-300 hover:animate-glow hover:bg-gradient-to-r hover:from-[#fda7bf] hover:to-pinkv focus:outline-none focus:ring-2 focus:ring-[#fda7bf] active:scale-95;
}
.site-button-alt {
  @apply relative rounded-md py-2 px-6 text-sm font-medium text-white 
    bg-gradient-to-r from-[#fda7bf] via-[#df5179] to-pinkv 
    bg-[length:200%_200%] hover:animate-shimmer
    animate-glow transition-transform duration-300 ease-in-out 
    hover:scale-105 active:scale-95 focus:outline-none 
    focus:ring-2 focus:ring-[#fda7bf];
}
.site-button-3 {
  @apply relative rounded-md py-2 px-4 text-sm font-medium text-white 
    bg-black/30 hover:bg-black/45 transition-all duration-300 hover:scale-105 active:scale-95 focus:outline-none focus:ring-2 focus:ring-[#fda7bf];

  /* Add pseudo-element for gradient border */
}
.site-button-3::before {
  @apply absolute inset-0 transition-opacity duration-200 rounded-md opacity-0 active:bg-black/45;
  content: '';
  z-index: -1;
}
.site-button-3:hover::before {
  @apply opacity-100;
}
/* MARK: Hover Cards */
/* Handles hover jiggle for cards */
/* .card-left::before {
  content: '';
  overflow: hidden;
  position: absolute;
  top: -20px;
  right: -120px;
  bottom: -35px;
  left: -20px;
  z-index: -10;
}
.card-right::before {
  content: '';
  overflow: hidden;
  position: absolute;
  top: -20px;
  right: -20px;
  bottom: -35px;
  left: -120px;
  z-index: -10;
} */
/* Hide default scrollbar */
/* Default scrollbar hidden (set opacity to 0) */
.card-center div.overflow-y-scroll::-webkit-scrollbar,
.card-left div.overflow-y-scroll::-webkit-scrollbar,
.card-right div.overflow-y-scroll::-webkit-scrollbar {
  width: 8px; /* Ensure scrollbar exists */
  transition: opacity 0.3s ease;
}
.card-center div.overflow-y-scroll::-webkit-scrollbar-thumb,
.card-left div.overflow-y-scroll::-webkit-scrollbar-thumb,
.card-right div.overflow-y-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}
.card-center div.overflow-y-scroll::-webkit-scrollbar-track,
.card-left div.overflow-y-scroll::-webkit-scrollbar-track,
.card-right div.overflow-y-scroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
/* Show scrollbar on hover */
.card-center:hover div.overflow-y-scroll::-webkit-scrollbar,
.card-left:hover div.overflow-y-scroll::-webkit-scrollbar,
.card-right:hover div.overflow-y-scroll::-webkit-scrollbar {
  opacity: 1; /* Make scrollbar visible */
}

/* MARK:EPANDABLE SCROLLBAR *
/* - unfortunate duplication leaving, in a hurry  */
/* Custom scrollbar for dynamic overflow-y-scroll */
.expandable-text-content::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.expandable-text-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5); /* Thumb color */
  border-radius: 4px; /* Rounded corners */
}

.expandable-text-content::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* Track color */
}

/* MARK: Header */
.header {
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  background: var(--color-black);
  display: flex;
  align-content: center;
  justify-content: space-around;
  padding: 25px;
  background-size: cover;
  z-index: 999;
  transition: transform 0.2s;
}

.swiper-button-prev {
  color: white !important;
}
.swiper-button-prev:hover {
  color: var(--color-hot-pink) !important;
  scale: 120%;
  transition-duration: 300ms;
}
.swiper-button-next {
  color: white !important;
}
.swiper-button-next:hover {
  color: var(--color-hot-pink) !important;
  scale: 120%;
  transition-duration: 300ms;
}

.underline-txt {
  text-decoration: underline;
  text-decoration-thickness: 0.1em;
  text-underline-offset: 2px;
  text-decoration-skip-ink: all;
  text-decoration-color: var(--color-hot-pink);
}

/* MARK: BANNER START */
/* .banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  color: var(--color-white);
} */

/* .banner-image {
  width: 340px;
  max-width: 420;
  height: auto;
  object-fit: contain;
  max-height: 100%;
  bottom: 0;
} */

/* .banner-logo {
  font-size: 6em;
  color: var(--color-hot-pink);
  font-weight: bold;
  text-shadow: 0.2rem 0.2rem 0.5rem var(--color-black);
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
} */

/* .quote-text-accent {
  color: var(--color-hot-pink);
  font-weight: bold;
} */

/*  MARK: BANER END*/
/*  3*/
/*  2*/
/* 1 */
.nav__hidden {
  transform: translateY(-199px);
}
.transparent {
  background-color: transparent;
}
.header__links {
  display: flex;
  align-items: center;
  gap: 20px;
}
.header__link {
  text-decoration: none;
  color: var(--color-white);
  font-size: 18px;
  text-transform: uppercase;
  padding: 2px 5px;
}
.header__link.transparent {
  color: white;
}
.header__link:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.1em;
  text-underline-offset: 2px;
  text-decoration-skip-ink: all;
  color: white;
  text-decoration-color: var(--color-hot-pink);
}
.header__link.transparent:hover {
  background-color: black;
}
.logo__text {
  font-size: 40px;
  text-decoration: none;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: var(--color-hot-pink);
}

.logo__link {
  text-decoration: none;
}
.header__menu {
  display: none;
}
.header__menu.transparent {
  color: white;
}
/* Footer */
.footer {
  padding: 0 0 30px 0;
  font-size: 14px;
}
.copyright {
  text-align: center;
  padding-top: 30px;
  color: var(--color-white);
}
/* Instagram */
.instagram {
  padding: 80px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.instagram__title {
  font-size: 42px;
  font-weight: 500;
}
a.instagram__button {
  font-family: Jost;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  border: 2px solid;
  padding: 14px 31px;
}
.instagram__button:hover {
  color: white;
  background-color: var(--color-hot-pink);
}

/* MARK: BREAKPOINTS */
/* laptop */
/* @media (max-width: 1280px) {
  .banner-image {
    width: 300px;
    height: auto;
  }
  .banner-logo {
    font-size: 5em;
  }
  .f-banner {
    text-transform: capitalize;
    color: var(--color-pink);
  }

  .text-under-logo {
    font-size: 2.5em;
  }
} */
/* Tablet breakpoint */
/* @media (max-width: 1024px) {
  .banner-image {
    max-width: 280px !important;
    height: auto;
    bottom: 0;
  }
} */

/* Mobile breakpoint */
@media (max-width: 768px) {
  /* .banner-image {
    width: 230px;
    height: auto;
    bottom: 0;
  }
  .banner-logo {
    font-size: 4em;
  }
  .text-under-logo {
    font-size: 2em;
  } */
  .header__menu {
    color: var(--color-dark-white);
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .menu__toggle {
    flex-direction: column;
    align-items: center;
  }
  .menu__toggle__item {
    list-style-type: none;
  }
  .menu__toggle__item:hover {
    text-decoration: underline;
    text-decoration-thickness: 0.1em;
    text-underline-offset: 2px;
    text-decoration-skip-ink: all;
    text-decoration-color: var(--color-hot-pink);
  }
  .menu__toggle__link {
    text-decoration: none;
    font-size: 22px;
  }
  .header {
    justify-content: space-between;
  }
  .header__links {
    display: none;
  }
}
/* Fix breakpoint */
@media (max-width: 640px) {
  /* .banner-image {
    width: 280px;
    height: auto;
    bottom: 0;
  } */
}
